body {
  margin: 0;
  font-family: "bd-console-variable", sans-serif;
  font-variation-settings: "GAME" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: crosshair;
}

code {
  font-family: "bd-console-variable", sans-serif;
}
