.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
  position: relative;
  top: -1em;
}



.App-link {
  color: #000;
}

 h1,h1::after {
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: transparent;
  position: relative;
}

h1::after {
  --move1: inset(50% 50% 50% 50%);
  --move2: inset(31% 0 40% 0);
  --move3: inset(39% 0 15% 0);
  --move4: inset(45% 0 40% 0);
  --move5: inset(45% 0 6% 0);
  --move6: inset(14% 0 61% 0);
  clip-path: var(--move1);
  content: 'GLITCH';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.effect::after {
  animation: glitch_4011 1s;
  text-shadow: 10 10px 10px black;
  animation-timing-function: steps(2, end);
  text-shadow: -3px -3px 0px #090e0d, 3px 3px 0px #b1abab;
  background-color: transparent;
  /* border: 3px solid black; */
}

.effect {
  text-shadow: -1px -1px 0px black, 1px 1px 0px #b1abab;
}

.effect {
  background-color: transparent;
  /* border: 1px solid black; */
  /* box-shadow: 0px 10px 10px -10px black; */
}

@keyframes glitch_4011 {
  0% {
    clip-path: var(--move1);
    transform: translate(0px,-10px);
  }

  10% {
    clip-path: var(--move2);
    transform: translate(-10px,10px);
  }

  20% {
    clip-path: var(--move3);
    transform: translate(10px,0px);
  }

  30% {
    clip-path: var(--move4);
    transform: translate(-10px,10px);
  }

  40% {
    clip-path: var(--move5);
    transform: translate(10px,-10px);
  }

  50% {
    clip-path: var(--move6);
    transform: translate(-10px,10px);
  }

  60% {
    clip-path: var(--move1);
    transform: translate(10px,-10px);
  }

  70% {
    clip-path: var(--move3);
    transform: translate(-10px,10px);
  }

  80% {
    clip-path: var(--move2);
    transform: translate(10px,-10px);
  }

  90% {
    clip-path: var(--move4);
    transform: translate(-10px,10px);
  }

  100% {
    clip-path: var(--move1);
    transform: translate(0);
  }
}
